import { uniq } from 'lodash';
import { curry, isEmpty, path, pluck, prop, sortBy } from 'ramda';
import { EVENTS, STREAM_TYPES, CHECKPOINT_GROUP, TABS } from '../../consts';
import { getUsers } from '../auth/selectors';

const ID = 'model';

export const getDefinitionId = path([ID, 'definitionId']);
export const getDashboardExams = curry((state) => {
	const exams = { ...state[ID]['dashboardExams'] };
	const users = getUsers()(state);

	exams.postponedExams = exams.postponedExams.map((exam) => ({
		...exam,
		assignedTo: users[exam.assignedTo]?.email ?? '-',
	}));

	return exams;
});
export const getConfigDefinitionId = path([ID, 'configDefinitionId']);
export const getCurrentConfigDefinition = curry((state) => state[ID]['definitions'][getConfigDefinitionId(state)]);
export const getDefinitions = curry((state) => state[ID]['definitions']);
export const getExamsForReviewCount = curry((state) => state[ID]['examsForReviewCount']);
export const getExamsForCommissionerReviewCount = curry((state) => state[ID]['examsForCommissionerReviewCount']);
export const getDefinitionsList = curry((state) => Object.values(state[ID]['definitions']));
export const getUnarchivedDefinitionsList = curry((state) =>
	Object.values(state[ID]['definitions']).filter((definition) => !definition.isArchived)
);
export const getSelectedDefinitions = curry((state) =>
	state[ID]['selectedDefinitionIds'].map((definitionId) => getDefinition(definitionId, state))
);
export const getDefinition = curry((definitionId, state) => state[ID]['definitions'][definitionId]);
export const getCurrentDefinition = curry((state) => state[ID]['definitions'][getDefinitionId(state)]);
export const getEvaluations = curry((state) => state[ID]['evaluations']);
export const getEvalsByTab = curry((state) => {
	const byTab = {};
	const evaluations = Object.values(state[ID]['evaluations']);

	for (const evaluation of evaluations) {
		const tab = evaluation.tab;
		if (!byTab[tab]) byTab[tab] = [];
		byTab[tab].push(evaluation);
	}

	return byTab;
});
export const getExamId = path([ID, 'examId']);
export const getChecklist = curry((definitionId, state) =>
	sortBy(
		prop('position'),
		Object.values(state[ID]['checklists']?.[definitionId] ?? {}).map((checklist) =>
			!checklist.subChecklists
				? checklist.runCheck === 'room'
					? { ...checklist, subChecklists: [] }
					: checklist
				: { ...checklist, subChecklists: sortBy(prop('position'), checklist.subChecklists) }
		)
	)
);

export const getProcessWasEdited = path([ID, 'checkpointProcessWasEdited']);

// returns exams sorted by checkpointType, streamType and checkpointGroup
export const getCheckpoints = curry((definitionId, state) => {
	return Object.values(state[ID]['checkpoints']?.[definitionId] ?? {}).sort((a, b) => {
		if (a.checkpointGroup === 'process_analysis') return -1;
		if (b.checkpointGroup === 'process_analysis') return 1;
		if (a.checkpointType < b.checkpointType) return -1;
		if (a.checkpointType > b.checkpointType) return 1;
		if (a.streamType < b.streamType) return -1;
		if (a.streamType > b.streamType) return 1;
		if (String(a.checkpointGroup) < String(b.checkpointGroup)) return -1;
		if (String(a.checkpointGroup) > String(b.checkpointGroup)) return 1;
		return 0;
	});
});
export const getCurrentCheckpoints = curry((state) => state[ID]['checkpoints'][getDefinitionId(state)]);
const getCurrentCheckpoint = curry(
	(checkpointId, state) => state[ID]['checkpoints'][getDefinitionId(state)]?.[checkpointId] ?? {}
);
export const getCurrentExam = curry((state) => state[ID]['exams'][getExamId(state)]);
export const getCurrentExams = curry((state) => {
	const exams = state[ID]['exams'];
	const users = getUsers()(state);

	return Object.values(exams).map((exam) => ({
		...exam,
		assignedTo: users[exam.assignedTo]?.email ?? '-',
		commissionerReviewedBy: users[exam.commissionerReviewedBy]?.email ?? '-',
		reviewerReviewedBy: users[exam.reviewerReviewedBy]?.email ?? '-',
		userRole: users[exam.assignedTo]?.role ?? '-',
	}));
});
export const getViolations = curry((state) => {
	const byCheckpointGroup = {};
	const violations = state[ID]['violations'];

	if (isEmpty(violations)) return byCheckpointGroup;

	const streamStartedAt = getCurrentExam(state)?.[EVENTS.STREAM_STARTED_AT] ?? 0;

	for (const { ...violation } of Object.values(violations)) {
		const currentCheckpoint = getCurrentCheckpoint(violation.checkpointId)(state);

		violation.checkpointGroup = currentCheckpoint.checkpointGroup;
		violation.streamType = currentCheckpoint.streamType;

		if (
			violation.streamType === STREAM_TYPES.SCREEN ||
			violation.streamType === STREAM_TYPES.SCREEN_CHUNKS ||
			violation.streamType === STREAM_TYPES.VIRTUALIZATION ||
			violation.checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS ||
			violation.streamType === 'displays'
		)
			violation.tab = TABS.SCREEN;
		else if (violation.streamType === STREAM_TYPES.IMAGE) violation.tab = TABS.IDENTIFICATION;
		else if (violation.checkpointGroup === CHECKPOINT_GROUP.ROOM_OBJECTS) violation.tab = TABS.ROOM;
		else violation.tab = TABS.CAMERA;

		if (!byCheckpointGroup[violation.checkpointGroup]) byCheckpointGroup[violation.checkpointGroup] = {};

		violation.createdAt = Date.parse(violation.createdAt) - Date.parse(streamStartedAt);
		byCheckpointGroup[violation.checkpointGroup][violation.id] = violation;
	}

	return byCheckpointGroup;
});
export const getViolationsRoomScan = curry((state) => {
	const byCheckpointGroup = {};
	const violations = state[ID]['violations'];

	if (isEmpty(violations)) return byCheckpointGroup;

	const streamStartedAt = getCurrentExam(state)?.[EVENTS.STREAM_STARTED_AT] ?? 0;

	for (const { ...violation } of Object.values(violations)) {
		const currentCheckpoint = getCurrentCheckpoint(violation.checkpointId)(state);

		violation.checkpointGroup = currentCheckpoint.checkpointGroup;
		violation.streamType = currentCheckpoint.streamType;

		if (!byCheckpointGroup[violation.checkpointGroup]) byCheckpointGroup[violation.checkpointGroup] = {};

		violation.createdAt = Date.parse(violation.createdAt) - Date.parse(streamStartedAt);
		byCheckpointGroup[violation.checkpointGroup][violation.id] = violation;
	}

	return byCheckpointGroup;
});
export const getRoomVideoUrl = curry((state) => state[ID]['exams']?.[getExamId(state)]?.['roomVideoUrl'] ?? '');
export const getWebcamUrl = curry((state) => state[ID]['exams']?.[getExamId(state)]?.['webcamUrl'] ?? '');
export const getScreenUrl = curry((state) => state[ID]['exams']?.[getExamId(state)]?.['screenUrl'] ?? '');
export const getIdUrl = curry((state) => state[ID]['exams']?.[getExamId(state)]?.['idUrl'] ?? '');
export const getFaceUrl = curry((state) => state[ID]['exams']?.[getExamId(state)]?.['faceUrl'] ?? '');
export const getNotesByCreatedAt = curry((state) => {
	const notesByCreatedAt = {};

	for (const note of Object.values(state[ID]['notes'])) {
		notesByCreatedAt[note.createdAt] = note;
	}

	return Object.keys(notesByCreatedAt)
		.sort()
		.map((time) => notesByCreatedAt[time]);
});
export const getNote = curry((noteId, state) => getNotesByCreatedAt(state).find((note) => note.id === noteId));
export const getInstructions = curry((state) => Object.values(state[ID]['instructions']));
export const getInstructionsByType = curry((state) => {
	const instructionsByType = {};
	for (const instruction of getInstructions()(state)) {
		instructionsByType[instruction.type] = instruction;
	}
	return instructionsByType;
});
export const getScalingDeployments = curry((state) => state[ID]['scaling']['deployments']);
export const getScalingNodes = curry((state) => state[ID]['scaling']['nodes']);
export const getScalingDb = curry((state) => state[ID]['scaling']['db']);
export const getScalingCluster = curry((state) => state[ID]['scaling']['cluster']);
export const getScalingHistory = curry((state) => state[ID]['scaling']['history']);
export const getScalingUsersCount = curry((state) => state[ID]['scaling']['usersCount']);
export const getShouldScaleModels = curry((state) => state[ID]['scaling']['shouldScaleModels']);
export const getScalingJobsCount = curry((state) => state[ID]['scaling']['jobsCount']);
export const getScalingJobId = curry((state) => state[ID]['scaling']['jobId']);
export const getScalingJobStatus = curry((state) => state[ID]['scaling']['jobStatus']);
export const getScalingAutoscalerStatus = curry((state) => state[ID]['scaling']['isAutoscalerEnabled']);
export const getRunningExamsCount = curry((state) => state[ID]['scaling']['runningExamsCount']);
export const getRunningJobsCount = curry((state) => state[ID]['scaling']['runningJobsCount']);
export const getStats = curry((state) => state[ID]['stats']);
export const getFilteredExams = curry((state) => {
	const exams = state[ID]['filteredExams']['exams'];
	const users = getUsers()(state);

	return Object.values(exams).map((exam) => ({
		...exam,
		assignedTo: users[exam.assignedTo]?.email ?? '-',
		reviewerReviewedBy: users[exam.reviewerReviewedBy]?.email ?? '-',
		commissionerReviewedBy: users[exam.commissionerReviewedBy]?.email ?? '-',
	}));
});
export const getFilteredExamsCount = curry((state) => state[ID]['filteredExams']['count']);
export const isPending = curry((actionType, state) => state[ID]['pending'][actionType]);
export const getTerms = curry((state) => uniq(pluck('term', getDefinitionsList(state))));
export const getTermsForExamFilter = curry((state) => uniq(pluck('term', getUnarchivedDefinitionsList(state))));

export const getDefNamesForExamFilter = curry((state) =>
	getUnarchivedDefinitionsList(state).map((d) => ({ value: d.id, name: d.name }))
);
export const getEvaluationDetails = curry((state) => state[ID]['evaluationDetails']);
export const getPreviousExams = curry((state) => state[ID]['previousExams']);
export const getAllNotes = curry((state) => state[ID]['allNotes']);
